import React from 'react'

export default function ProductivityIcon(props) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="-849 838 100 125"
      fill="currentColor"
      {...props}
    >
      <path
        d="M-834,907.4c-2.7-7.4-3.2-15.4-1.6-23.1c1.7-7.9,5.5-15.2,11.2-21c16.4-16.8,43.3-17.1,60.1-0.7l0.9,0.9l-0.1-5.7
		 c0-0.7,0.5-1.2,1.2-1.2l0,0c0.7,0,1.2,0.5,1.2,1.2l0.1,8.6c0,0.7-0.5,1.2-1.2,1.2l-8.6,0.1l0,0c-0.7,0-1.2-0.5-1.2-1.2
		 s0.5-1.2,1.2-1.2l5.7-0.1l-0.9-0.9c-7.7-7.4-17.8-11.5-28.5-11.4c-10.7,0.2-20.7,4.4-28.2,12.1c-10.8,11.1-14.2,27-9,41.6
		 c0.2,0.6-0.1,1.3-0.7,1.6c-0.1,0-0.3,0.1-0.4,0.1C-833.4,908.2-833.8,907.9-834,907.4z M-765.3,921c-15.5,15.8-40.9,16.1-56.7,0.7
		 l-0.9-0.9l5.7-0.1c0.7,0,1.2-0.6,1.2-1.2c0-0.7-0.6-1.2-1.2-1.2l-8.6,0.1c-0.7,0-1.2,0.6-1.2,1.2l0.1,8.6c0,0.7,0.6,1.2,1.2,1.2l0,0
		 c0.7,0,1.2-0.6,1.2-1.2l-0.1-5.7l0.9,0.9c8.3,8.1,19,12.1,29.7,12.1c11,0,22.1-4.3,30.4-12.8c5.7-5.8,9.6-13.1,11.2-21
		 c1.6-7.7,1.1-15.7-1.6-23.1c-0.2-0.6-0.9-1-1.6-0.7c-0.6,0.2-1,0.9-0.7,1.6C-751.1,894-754.5,909.9-765.3,921z M-808.1,877.5
		 c0.2-0.2,0.3-0.5,0.2-0.7l-0.9-3.4c-0.4-1.4,0.3-2.9,1.7-3.5l4.3-2c0.4-0.2,0.8-0.3,1.3-0.3c1,0,1.9,0.5,2.5,1.3l2,2.9
		 c0.1,0.2,0.4,0.3,0.7,0.3c1.2-0.1,2.3-0.2,3.5-0.1l0,0c0.3,0,0.6-0.1,0.7-0.4l1.7-3c0.5-0.9,1.5-1.5,2.6-1.5c0.4,0,0.7,0.1,1.1,0.2
		 l4.4,1.7c1.4,0.5,2.2,2,1.9,3.4l-0.7,3.5c0,0.3,0.1,0.5,0.3,0.7c0.8,0.7,1.7,1.4,2.4,2.3c0.2,0.2,0.5,0.3,0.7,0.2l3.4-0.9
		 c1.5-0.4,3,0.4,3.5,1.7l2,4.3c0.6,1.3,0.2,2.9-1.1,3.8l-3,2c-0.2,0.1-0.3,0.4-0.3,0.7c0.1,1.1,0.2,2.2,0.1,3.3
		 c0,0.3,0.1,0.5,0.4,0.7l3,1.8c1.3,0.7,1.8,2.3,1.3,3.7l-1.6,4.3c-0.4,1.2-1.6,2-2.9,2c-0.2,0-0.4,0-0.6-0.1l-3.5-0.7
		 c-0.3-0.1-0.5,0.1-0.7,0.3c-0.7,0.9-1.4,1.7-2.3,2.4c-0.2,0.2-0.3,0.5-0.2,0.7l0.9,3.4c0.4,1.4-0.3,2.9-1.7,3.5l-4.3,2
		 c-0.4,0.2-0.8,0.3-1.3,0.3c-1,0-1.9-0.5-2.5-1.3l-2-3c-0.1-0.2-0.4-0.3-0.7-0.3c-1.1,0.1-2.1,0.2-3.2,0.1l0,0
		 c-0.3,0-0.6,0.1-0.7,0.4l-1.8,3.1c-0.5,0.9-1.6,1.5-2.6,1.5c-0.4,0-0.7-0.1-1.1-0.2l-4.4-1.7c-1.4-0.5-2.2-2-1.9-3.4l0.7-3.5
		 c0.1-0.3-0.1-0.5-0.3-0.7c-0.9-0.7-1.7-1.5-2.5-2.3c-0.2-0.2-0.5-0.3-0.7-0.2l-3.4,0.9c-1.4,0.4-2.9-0.4-3.5-1.7l-2-4.3
		 c-0.6-1.3-0.2-2.9,1.1-3.8l3-2c0.2-0.2,0.3-0.4,0.3-0.7c-0.1-1.1-0.2-2.2-0.1-3.4c0-0.3-0.1-0.5-0.4-0.7l-3.1-1.8
		 c-1.3-0.7-1.8-2.3-1.3-3.7l1.7-4.4c0,0,0,0,0-0.1c0.5-1.1,1.6-1.9,2.8-1.9c0.2,0,0.4,0,0.6,0.1l3.5,0.7c0.3,0.1,0.5-0.1,0.7-0.3
		 C-809.7,879.1-808.9,878.2-808.1,877.5z M-808.5,881.5c-0.7,0.9-1.9,1.4-3.1,1.2l-3.5-0.7h-0.1c-0.2,0-0.5,0.2-0.6,0.4l-1.6,4.4
		 c-0.1,0.3,0,0.6,0.3,0.7l3.1,1.8c1,0.6,1.6,1.7,1.6,2.9c0,1,0,2,0.1,3c0.1,1.2-0.4,2.3-1.4,3l-2.9,2c-0.2,0.2-0.3,0.5-0.2,0.7l2,4.3
		 c0.1,0.3,0.4,0.4,0.7,0.3l3.4-0.9c0.3-0.1,0.5-0.1,0.8-0.1c0.9,0,1.7,0.4,2.4,1c0.7,0.7,1.4,1.4,2.2,2c0.9,0.7,1.4,1.9,1.2,3.1
		 l-0.9,3.4c-0.1,0.3,0.1,0.6,0.4,0.7l4.4,1.7c0.3,0.1,0.6,0,0.7-0.3l1.8-3.1c0.6-1,1.7-1.6,2.9-1.6c0.9,0,1.9,0,2.8-0.1
		 c1.2-0.1,2.3,0.4,3,1.4l2,3c0.2,0.2,0.5,0.3,0.7,0.2l4.3-2c0.3-0.1,0.4-0.4,0.3-0.7l-0.9-3.4c-0.3-1.2,0.1-2.4,0.9-3.2
		 c0.7-0.7,1.4-1.4,2-2.1c0.7-0.9,1.9-1.4,3.1-1.2l3.5,0.7c0.3,0.1,0.6-0.1,0.7-0.4l1.7-4.4c0.1-0.3,0-0.6-0.3-0.7l-3-1.8
		 c-1-0.6-1.6-1.7-1.6-2.9c0.1-1,0-2-0.1-2.9c-0.1-1.2,0.4-2.3,1.4-3l3-2c0.2-0.2,0.3-0.5,0.2-0.7l-2-4.3c-0.1-0.3-0.4-0.4-0.7-0.3
		 l-3.2,0.9c-1.1,0.3-2.4-0.1-3.2-0.9c-0.7-0.7-1.4-1.4-2.1-2c-0.9-0.7-1.4-1.9-1.2-3.1l0.7-3.5c0.1-0.3-0.1-0.6-0.4-0.7l-4.4-1.7
		 c-0.3-0.1-0.6,0-0.7,0.3l-1.7,3c-0.6,1-1.7,1.6-2.9,1.6c-1-0.1-2,0-3,0.1c-1.2,0.1-2.4-0.4-3-1.4l-2-2.9c-0.2-0.2-0.5-0.3-0.7-0.2
		 l-4.3,2c-0.3,0.1-0.4,0.4-0.3,0.7l0.9,3.4c0.3,1.1-0.1,2.4-0.9,3.2C-807.2,879.9-807.9,880.7-808.5,881.5z M-802.9,897.1
		 c-1.1-2.4-1.3-5-0.4-7.5c1.4-3.9,5.1-6.5,9.2-6.5c1.1,0,2.2,0.2,3.3,0.6c2.5,0.9,4.5,2.7,5.6,5c1.1,2.4,1.3,5,0.4,7.5
		 c-1.4,3.9-5.1,6.5-9.2,6.5c-1.1,0-2.2-0.2-3.3-0.6C-799.7,901.3-801.7,899.5-802.9,897.1z M-800.6,896.1c0.8,1.8,2.3,3.1,4.2,3.8
		 c0.8,0.3,1.6,0.4,2.5,0.4c3.1,0,5.9-2,6.9-4.9c0.7-1.9,0.6-3.9-0.3-5.6c-0.8-1.8-2.3-3.1-4.2-3.8c-0.8-0.3-1.6-0.4-2.5-0.4
		 c-3.1,0-5.9,2-6.9,4.9C-801.6,892.3-801.5,894.3-800.6,896.1z"
      />
    </svg>
  )
}
