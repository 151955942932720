import React from 'react'

export default function FocusedIcon(props) {
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="-849 838 100 125"
      stroke="currentColor"
      {...props}
    >
      <g strokeWidth={0.75} strokeMiterlimit={10} fill="currentColor">
        <path
          d="M-832.5,882.5c0.7,0,1.3-0.6,1.3-1.3l0,0v-9.3c0-4.5,3.6-8,8-8h9.3c0.7,0,1.3-0.6,1.3-1.3
			 c0-0.7-0.6-1.3-1.3-1.3h-9.3c-2.9,0-5.6,1.2-7.5,3.1c-1.9,1.9-3.1,4.6-3.1,7.5v9.3C-833.8,881.9-833.2,882.5-832.5,882.5z"
        />
        <path
          d="M-762.5,912.5c-0.7,0-1.3,0.6-1.3,1.3l0,0v9.3c0,2.2-0.9,4.2-2.4,5.7c-1.5,1.5-3.5,2.4-5.7,2.4h-9.3
			 c-0.7,0-1.3,0.6-1.3,1.3s0.6,1.3,1.3,1.3h9.3c5.9,0,10.6-4.8,10.7-10.7v-9.3C-761.2,913.1-761.8,912.5-762.5,912.5z"
        />
        <path
          d="M-823.2,933.8h9.3c0.7,0,1.3-0.6,1.3-1.3c0-0.7-0.6-1.3-1.3-1.3l0,0h-9.3c-2.2,0-4.2-0.9-5.7-2.4
			 c-1.5-1.5-2.3-3.5-2.3-5.7v-9.3c0-0.7-0.6-1.3-1.3-1.3c-0.7,0-1.3,0.6-1.3,1.3v9.3c0,2.9,1.2,5.6,3.1,7.5
			 C-828.8,932.6-826.1,933.8-823.2,933.8z"
        />
        <path
          d="M-761.2,871.8c0-2.9-1.2-5.6-3.1-7.5c-1.9-1.9-4.6-3.1-7.5-3.1h-9.3c-0.7,0-1.3,0.6-1.3,1.3
			 c0,0.7,0.6,1.3,1.3,1.3h9.3c2.2,0,4.2,0.9,5.7,2.3c1.5,1.5,2.4,3.4,2.4,5.7v9.3c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3l0,0
			 L-761.2,871.8L-761.2,871.8z"
        />
        <path
          d="M-784,896.2h-12.2V884c0-0.7-0.6-1.3-1.3-1.3s-1.3,0.6-1.3,1.3v12.2H-811c-0.7,0-1.3,0.6-1.3,1.3
			 c0,0.7,0.6,1.3,1.3,1.3h12.2V911c0,0.7,0.6,1.3,1.3,1.3s1.3-0.6,1.3-1.3v-12.2h12.2c0.7,0,1.3-0.6,1.3-1.3
			 C-782.7,896.8-783.3,896.2-784,896.2z"
        />
      </g>
    </svg>
  )
}
